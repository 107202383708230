// popup styles
$box-bg: rgba(255, 255, 255, 0.2);
$overlay-bg: rgba(255, 255, 255, 0.9);

.box {
  width: 40%;
  margin: 0 auto;
  background: $box-bg;
  padding: 35px;
  border: 2px solid $color-white;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay-bg;
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  background: $color-white;
  width: 100%;
  position: relative;
  transition: all 5s ease-in-out;
  max-width: 540px;
  box-sizing: content-box;
  box-shadow: 0px 0px 50px rgba(128, 156, 153, 0.25);
  .price {
    margin-right: 5px;
  }
}

.popup-body h2 {
  margin-top: 0;
  color: $color-primary-dark;
  font-size: 20px;
}

.product-description {
  font-size: 14px;
}

.popup .close {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 200ms;
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
  color: $color-primary-dark;
  background-color: $color-white;
  width: 30px;
  height: 30px;
  padding: 5px;
  text-align: center;
  font-weight: 400;
  line-height: initial;
  box-sizing: content-box;
  line-height: 27px;
  border-radius: 100%;
}

.mbi-icon {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.mbi-title {
  font-size: 18px;
  color: $color-body-font;
  font-weight: bold;
  padding: 3px 0;
}

.icon-gold {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: auto;
  // background: url('{{ get_asset_url("./images/gold.png")}}');
}
.icon-large {
  img {
    width: 100%;
  }
}
.icon-vehicle {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  // background: url('{{ get_asset_url("./images/gold.png")}}');
}

.popup .close:hover {
  cursor: pointer;
  color: $color-primary;
}

.popup .content {
  max-height: 50%;
  min-height: 50px;
  overflow: auto;
}

.popup-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gray-light;
  width: 100%;
  min-height: 215px;
  max-height: 240px;
  overflow: hidden;

  .icon {
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
  }
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mileage-popup {
  .popup-header {
    display: flex;
    align-items: center;
    min-height: 215px;
    max-height: 240px;
    overflow: hidden;
    .header-inner {
      display: block;
      text-align: center;
    }
    .icon-bg {
      background-color: $color-white;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      margin: 0 auto;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-gold {
        width: 31px;
        height: 31px;
        background-size: contain;
      }

      img {
        width: 50%;
        height: auto;
      }
    }
  }
  .modal-title-wrapper {
    margin-left: 0px;
  }
  .modal-title {
    font-size: 28px;
    font-weight: bold;
    color: $color-body-font;
    margin: 0;
    line-height: inherit;
  }
  .modal-sub-title {
    font-size: 14px;
    font-weight: bold;
    color: $color-primary;
    margin: 0;
    line-height: inherit;
  }
  h2 {
    font-size: 18px;
  }
  input.input-style {
    width: 60%;
  }
}
.detail-popup {
  .manage-coverage {
    // margin-top: 20px;
    text-align: right;
  }
  .update-card-info {
    text-align: left;
  }
  .pd-pop-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .help-text {
    font-size: 13px;
    padding-right: 10px;
    color: #9e9e9e;
    font-weight: 700;
  }
}

.keyprotect-popup {
  .mbi-title {
    text-align: center;
    line-height: 20px;
    span {
      color: $color-primary;
    }
  }
  .popup-header {
    background-color: $color-white;
    min-height: 120px;
    max-height: 120px;
  }
  .popup-body {
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    .small-txt {
      color: $color-gray-light8;
    }
    .term-policy-parent {
      justify-content: center;
      a {
        font-weight: bold;
        font-size: 12px;
      }
    }

    .confirmation-txt {
      font-weight: 400;
      font-size: 18px;
    }

    .confirmation-popup-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        width: 63px;
        box-sizing: border-box;
      }
    }
  }
  &.keyprotect-order {
    .small-txt {
      color: $color-gray-dark;
    }
  }
}

.popuppdf {
  margin: 70px auto;
  background: $color-white;
  width: 40%;
  transition: all 5s ease-in-out;
  padding: 10px;
}

.popuppdf .close {
  float: right;
  transition: all 200ms;
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
  color: $color-primary;
  cursor: pointer;
}

// Confrimation popup

.confirmation-popup {
  .confirmation-popup-wrap {
    .confirmation-popup-body {
      h2 {
        text-align: center;
        margin-bottom: 0px;
        color: $color-body-font;
        font-size: 24px;
      }
      h3 {
        text-align: center;
        margin-top: 10px;
        color: $color-primary;
        font-size: 18px;
      }
      p {
        text-align: center;
        color: $color-gray-light7;
        margin: 20px 0;
      }
    }

    .confirmation-popup-footer {
      text-align: center;
      a {
        margin: 0 5px;
      }
    }
  }
}

// login popup

.login-popup {
  .login-popup-wrap {
    max-width: 300px;
    margin: 0 auto;
    .login-popup-body {
      h2 {
        text-align: left;
        margin-bottom: 0px;
        color: $color-body-font;
        font-size: 21px;
      }
      h3 {
        text-align: left;
        margin-top: 0;
        color: $color-body-font;
        font-size: 21px;
        font-weight: 400;
      }

      h4 {
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-light6;
      }

      p {
        text-align: left;
        color: $color-gray-light7;
        margin: 20px 0;
      }

      .forgot-pass {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        a {
          font-weight: bold;
          margin-left: 5px;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        span {
          font-size: 12px;
          font-weight: 700;
          color: $color-body-font;
        }
      }
      .message {
        a {
          margin-left: 0px;
        }
      }
    }

    .login-popup-footer {
      text-align: center;
      a {
        margin: 0 5px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .mileage-popup {
    input.input-style {
      width: 94%;
    }
  }
}

@media screen and (max-width: 388px) {
  .mileage-popup {
    input.input-style {
      width: 93%;
    }
  }
}
@media screen and (max-width: 280px) {
  .mileage-popup {
    input.input-style {
      width: 91%;
    }
  }
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .popup {
    width: 100%;
  }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .overlay {
    .popup {
      width: calc(100% - 40px);
    }
  }
}
