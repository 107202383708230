.email-tooltip-icon{
    background: url(//f.hubspotusercontent40.net/hubfs/8652841/raw_assets/public/autoconcept-module/images/info_icon.svg) no-repeat;
    height: 17px;
    width: 17px;
    cursor: pointer;
    position: absolute;
    margin-left: 10px;
    margin-top: -4px;
}

.email-tooltip-box{
    box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.15);
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    max-width: 400px;
    width: 400px;
    color: #03687c;
    text-align: left;
    background-color: #fff;
    margin-left: 80px;
    margin-top: -30px;
    font-weight: unset;
    z-index: 1;
}

/* Small devices */
@media only screen and (max-width: 768px) {
    .email-tooltip-box{
        max-width: 200px;
        width: 200px;
    }
}