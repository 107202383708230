.claim-form {
  margin-top: 20px;
  .ant-form-item-control-input-content {
    input {
      border: 1px solid #d9d9d9;
      &:focus {
        border: 1px solid #4096ff;
      }
    }
    .dateinput {
      width: 100%;
      input {
        border: 0;
        &:focus {
          border: 0;
        }
      }
    }
    .ant-input-number {
      width: 100%;
    }
    .ant-input-number-input {
      border: 0;
      width: 100%;
    }
  }
  .multiLabel {
    color: #000;
    width: 100%;
    padding-right: 20px;
    span {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
    }
    p {
      color: #888;
      font-size: 12px;
      font-weight: 400;
    }
    a {
      color: #428bca;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .bottomForm {
    .ant-row {
      .ant-col {
        max-width: 100%;
      }
    }
  }
  .ant-upload {
    button {
      border: 1px solid #000;
      align-items: center;
      display: flex;
    }
  }
  .example {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    border-radius: 4px;
    top: 0;
    left: 0;
    .ant-spin {
      margin-top: 20%;
    }
  }
  .ant-btn-default {
    border: 1px solid;
    background-color: #fff;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    margin-left: 10px;
  }
  .ant-form-item-control {
    flex: 1 1 100%;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      position: absolute;
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
      right: -5px;
    }
  }
  .workshop-error-msg {
    font-size: 14px;
    color: #ff4d4f;
    margin: 0;
  }
}
