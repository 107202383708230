.new-vehicle-module .mid-container, 
.new-customer-flow.mid-container{
    max-width: 1200px;
    h1{
        margin-bottom: 0;
    }
    .sub-heading{
        font-weight: bold;
        color: $color-gray-light7;
    }
   
}

@media screen and (max-width: 915px){
    .new-cus-img{
     display: none;
    }
}

