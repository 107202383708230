
// data table variables

$tb-heading-bg:#f9f9f9;
$tb-heading-txt:#9E9E9E;
$tb-primary-color:#03687C;
$tb-border-color:#F1F1F1;
// small component for data table

.divTable{
  display: table;
  width: 100%;
    .divTableRowHeading{
      display: table-row;
      background-color:$tb-heading-bg;
      .divTableCell{
        font-size: 12px;
        line-height: 13.8px;
        color:$tb-heading-txt;
        display: table-cell;
        padding: 13px 10px;
        font-weight: 700;
        vertical-align: middle;
        &:first-child{
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child{
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          min-width: 70px;
        }
        img{
          vertical-align: inherit;
        }
      }
    }
    .divTableRow{
      display: table-row;
      .divTableCell{
        display: table-cell;
        border-bottom: 1px solid $tb-border-color;
        padding: 18px 10px;
        font-size: 16px;
        color: $color-body-font;
        font-weight: bold;
        vertical-align: middle;
        &.font-18-bold{
          font-size: 18px;
          line-height: 20.7px;
          font-weight: 700;
        }
        &.color-primary{
          color:$tb-primary-color;
        }
        &.docCell{
          img{
            padding-right: 10px;
          }
        }
        img{
          vertical-align: inherit;
        }
      }
    }

    select{
      background: url('../images/arrow_down.svg') no-repeat right 10px center transparent;
      -webkit-appearance: none;
      appearance: none;
      border: 0;
      padding: 0 10px 0 0;
      height: 45px;
      width: 55px;
      font-size: 16px;
      color:$color-body-font;
      cursor: pointer;
      font-weight: 700;
      &:focus{
        outline: none;
      }
      &:disabled{
        opacity: 1;
      }
      option{
        page-break-after: 10px;
      }
    }
    .react-datepicker-wrapper{
      width: 105px;
      .react-datepicker__input-container{
            input{
              background: url('../images/calendar_icon.svg') no-repeat right 3px center transparent;
              cursor: pointer;
              padding: 0 10px 0 0;
              font-size: 16px;
              color: $color-body-font;
              font-weight: 700;
              &:focus{
                outline: none;
              }
            }
          }
    }
    .icon-pdf-red,
    .icon-pdf-blue{
      margin-right: 5px;
    }
    .column-or{
      text-align: center;
      span{
        font-size: 12px;
        background-color: $color-primary;
        border-radius: 3px;
        color: $color-white;
        padding: 3px 5px;
        font-weight: 400;
      }
      
    }
    .sepa{
      left: 0%;
      top: 0%;
      width: 1px;
      background-color:$color-gray-light8;
      height: 25px;
      text-align: center;
      margin: 7px auto;
    }
     .tb-end-date{
       min-width:82px;
  }
}

.reviewhelp-text {
  font-size: 13px;
  padding-right: 10px;
  color: #9e9e9e;
  font-weight: 700;
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .divTable {
    .divTableRow {
      .divTableCell{
        position: relative;
        padding: 18px 10px;
        font-size: 12px;
        &:first-child{
          padding: 18px 14px;
        }
        .icon-remove1 {
          position: absolute;
          top: 33%;
        }
        &.font-18-bold{
          font-size: 14px;
          line-height: 20.7px;
          font-weight: 700;
        }
        &.last-col {
          min-width: 70px;
        }
        .sepa{
          top: 25%;
        }
        .react-datepicker__tab-loop {
          background-color: rgba(0,0,0,.2);
          border: 0;
          height: 100vh;
          width: 100vw;
          position: fixed;
          top: 0;
          left: 0;
          .react-datepicker-popper {
            top: 40% !important;
            left: 15% !important;
            width: calc(100% - 40px);
          }
        }
      }
    }
    select{
      background: url('../images/arrow_down.svg') no-repeat right 10px center transparent;
      font-size: 12px;
    }
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
            input{
              background: url('../images/calendar_icon.svg') no-repeat right 3px center transparent;
              font-size: 12px;
            }
          }
    }
  }
  .tb-review-order{
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      -webkit-overflow-scrolling: auto;
      width: 7px;
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
  .tb-end-date{
    min-width:64px;
  }
}