
// Insure another vehicle

.insure-another-panel{
        display: flex;
        align-items: center;
        background:$color-gray-light5;
        padding: 30px;
        justify-content: space-between;
        flex-wrap: wrap;

        .icon-background{
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color-gray-light;
          border-radius: 50%;
          width: 72px;
          height: 72px;

          .insure-another-icon{
            background:url('../images/add.png');
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
          }
        }
}