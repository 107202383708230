@import 'colors';
/* Custom properties */

:root {
    --tooltip-text-color: $color-primary;
    --tooltip-background-color: $color-white;
    --tooltip-margin: 35px;
    --tooltip-arrow-size: 6px;
  }
  
  /* Wrapping */
  .Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;

    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
    box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.15);
    border-radius: 5px;
    padding: 10px;
    font-size: 11px;
    color: $color-primary;
    background-color: $color-white;
  }
  
  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.top {
    top: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: $color-white;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: $color-white;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1.8);
  }

  
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: $color-white;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color:$color-white;
  }
