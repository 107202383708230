@import "colors";

.login-container {
  color: $color-gray-dark;
  a {
    cursor: pointer;
    font-size: 14px;
  }
}
.policy-container {
}
.pass-reset,
.ccept-policy,
.pass-change {
  width: 100%;
}
.success {
  color: $color-green;
  font-size: 14px;
  padding: 10px 0;
  font-weight: 400;
}

.tooltip-icon {
  background: url(//f.hubspotusercontent40.net/hubfs/8652841/raw_assets/public/autoconcept-module/images/info_icon.svg)
    no-repeat;
  height: 17px;
  width: 17px;
  cursor: pointer;
  position: absolute;
  margin-left: 10px;
  margin-top: -4px;
}

.tooltip-box {
  box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.15);
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  max-width: fit-content;
  color: #03687c;
  text-align: left;
  background-color: #fff;
  margin-left: 35px;
  margin-top: -20px;
  font-weight: unset;
}
