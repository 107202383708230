.owner-details{
    .rever-btn-wrapper{
      position: relative;
      .info-icon{
        right: -28px;
        bottom: -1px;
      }
      .infomation-popup{
        background-color: $color-white;
        z-index: 1;
        right: 0;
        top: -10px;
      }
      .btn-back {
        padding: 5px 20px;
      }
    }
     .per-number{
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
      }

      .vo-checkbox-wrap{
        a{
          font-weight: bold;
          text-decoration: underline !important;
          &:hover{
            text-decoration: none  !important
          }
        }
      }
  }

  // new vehicle flow
  .vo-have-account{
        font-size: 14px;
        color: $color-body-font;
        font-weight: 700;
        padding: 10px;

            border-radius: 8px;
            box-sizing: border-box;
            background: $color-gray-light5;
        a{
          color: $color-primary;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
  }

  .emptyd-div-tag{
    height: 66px;
    margin-bottom: 30px;
  }

  .od-form{
    .od-row {
      position: relative;
      .form-validation {
            font-size: 12px;
            font-weight: 400;
            color:$color-red;
            position: absolute;
            bottom: -18px;
      }
    }
    
  }

@media screen and (max-width: 767px){
  .owner-details{
    .form-section{
      display: block;
    }
  }

  .emptyd-div-tag{
    height: 0px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 375px){
    .infomation-popup{
    max-width: 90px;
    width: 100%;
    right: 0;
    }

    .emptyd-div-tag{
      height: 0px;
      margin-bottom: 0px;
    }
  }

  /* Small devices */
@media only screen and (max-width: 768px) {
  .information-module {
    .vo-have-account {
      margin-bottom: 0;
      padding: 10px;
    }
    .personal-number {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;
      input,
      a {
        margin-top: 0;
        width: calc(50% - 10px);
      }
      a {
        margin-left: 5px;
      }
    }
    .vo-checkbox-wrap {
      > div {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}