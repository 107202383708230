// body{
//   font-family: Helvetica;
//   font-size: 16px;
// }

h1,h2,h3,h4,h5,h6{
    font-weight: bold;
    color: $color-primary;  
}

h1{
    font-size: 36px;    
}
h2{  
    font-size: 34px;
}
h3{ 
    font-size: 32px;
}

h4{
    font-size: 30px;
}

h5{
    font-size: 20px;
    font-weight: 400;
    color: $color-body-font;
}

h6{
    font-size: 20px;
}


.cancel-right-text{
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 131.99%;
    /* identical to box height, or 16px */
    
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    
    color: #03687C;
}

@media screen and (max-width: 752px){
    h1{
        font-size:28px;
    }
}
