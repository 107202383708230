.input-label{
    font-size: 12px;
    color: $color-primary;
    font-weight: 700;
    
}
.input-label-lg{
    font-size: 14px;
    color: $color-primary;
    font-weight: 700;
}

.input-style{
  border: none;
  padding: 10px;
  background: $color-gray-light2;
  -webkit-appearance: none;
  font-size: revert;
  border-radius: 5px;
}

.input-style-2{
  text-indent:10px;
  border: none;
  padding: 0;
  background: $color-gray-light2;
  height: 25px;
  box-sizing: content-box;
  font-size: revert;
  border: 1px solid transparent;
  border-radius: 5px;
   &:focus{
    border: 0 solid $color-primary;
    outline: none;
  }
  
}

.form-field{
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: $color-primary;
  border-radius: 5px;
}

.input-style-3{
  border: none;
  padding: 5px 10px;
  font-size: 16px !important;
  font-weight: 400;
  max-width: 370px;
  width: 100%;
  color:$color-body-font;
  font-size: revert;
  -webkit-appearance: none;
  border-radius: 5px;
  &:focus{
    border: 1px solid $color-primary;
    outline: none;
  }
}
.input-label-style-2{
  font-size: 16px;
  color:$color-primary;
  font-weight: 700;
  padding: 0 20px 0 0;
  -webkit-appearance: none;
  
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}
input[type=text],
input[type=password],
input[type=number]{
  &:focus{
    border: 0px solid $color-primary;
    outline: none;
  }
  border: 1px solid transparent;
}


input:focus::placeholder {
  color: transparent;
}


select{
  background: url('../images/arrow_down.svg') no-repeat right 10px center $color-gray-light2;
  -webkit-appearance: none;
  appearance: none;
    border: 0;
    padding: 10px;
    height: 36px;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid transparent;
    outline: none;
    font-size: 13px;
    border-radius: 5px;
    &:focus{
      border: 1px solid $color-primary;
    }
}

.checkbox{
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color:$color-gray-light;
}

.checkbox:hover input ~ .checkmark {
  background-color: $color-gray-light8;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  box-sizing: initial;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid $color-primary;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: initial;
}


@media screen and (max-width: 767px){
  .input-label-style-2{
    margin-bottom: 10px;
    display: block;
  }
  .input-style-3{
    max-width: 100%;
  }
  .form-field{
    width: 48%;
    margin-bottom: 0;
  }
  
}