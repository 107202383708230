$color-body-font:#454545;
$color-gray-dark:#888;
$color-gray-dark1:#333;
$color-primary:#03687C;

$color-gray-light:#e5edee;
$color-gray-light2:#F4F4F4;
$color-gray-light3:#d6d6d6;
$color-gray-light4:#e6e6e6;
$color-gray-light5:#f9f9f9;
$color-gray-light6:#999;
$color-gray-light7:#A0A0A0;
$color-gray-light8:#C4C4C4;

$color-white:#fff;
$color-primary-light:#078ca7;
$color-primary-dark:#1e687b;
$color-green:#6acca8;
$color-green-2:#5DC1A9;

$color-red:#ff4141;
$color-dark-red:#c00000;
$color-dark-red2:#F06969;

.dark-red{
    color: #c00000;
}