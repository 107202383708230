// Thank you page

.thankyou-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  .confirm-icon-background{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #E5EDEE;
      border-radius: 10px;
      width: 200px;
      height: 200px;

    .confrim-icon{
      background: url('../../../images/check.svg');
      width: 72px;
      height: 72px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
    }
    .confrim-icon-accepted{
      background: url('../../../images/check.svg');
      width: 72px;
      height: 72px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
    }
  }

  .content-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
     max-width: 600px;
  }

  h1{
    margin: 0px;
    text-align: center;
    color: #03687C;
    margin-bottom: 5px;
    font-size: 38px;
  }

  p{
    margin: 0px;
    text-align: center;
    color: #999;
    margin-bottom: 0;
  }
  .contact-info{
    font-size: 16px;
    line-height: 18px;
  }
  .order-info-th{
    font-size: 14px;
    // color: #A0A0A0;
    font-weight: bold;
    padding: 5px;
    justify-content: center;
    width: 165px;
  }
   .order-info-td{
    font-size: 14px;
    color: #999;
    padding: 5px;
    width: 145px;
  }

  .order-info{
    .cart-card{
      background: #66666614;
      padding: 10px;
      margin-right: 0;
      border-radius: 8px;
      box-sizing: border-box;
    }
    .product-ml{
      margin-left: 30px;
    }
    .price-inner-ml{
      margin-left: 70px;
    }
    .orderNo-inner-ml{
      margin-left: 10px;
      float: right;
    }
  }

  @media screen and (max-width: 770px){
    .order-info{
      .product-ml{
        margin-left: 20px;
      }
    }
  }

}

/* Small devices */
@media only screen and (max-width: 768px) {
  .thankyou-wrapper{
    margin-top: 10px;
    .confirm-icon-background {
      display: none;
      width: 100px;
      height: 100px;
      .confrim-icon-accepted {
        width: 50px;
        height: 50px;
        background-size: contain;
      }
    }
    .content-wrap {
      h1 {
        margin-bottom: 10px !important;
        font-size: 28px;
      }
    }
    .cart-card {
      display: block;
    }
    p {
      margin-bottom: 10px;
    }
  }
}