.react-datepicker-wrapper {
    input{
        border: none;
        padding: 10px;
        background: $color-gray-light2;
        height: 36px;
        width: 100%;
        box-sizing: border-box;
        background: url('../images/calendar_icon.svg') no-repeat right 10px center $color-gray-light2;
        cursor: pointer;
        font-size: revert;
        border-radius: 5px;
    }
}

.react-datepicker__navigation--next {
    fill: $color-primary;
    background: url('../images/right-arrow.svg') no-repeat center;
    width: 10px;
    height: 10px;
    border: none;
}

.react-datepicker__navigation--previous {
    fill: $color-primary;
    background: url('../images/left-arrow.svg') no-repeat;
    width: 10px;
    height: 10px;
    border: none;
}


