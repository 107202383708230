.warranty-registration-module {
  overflow-x: hidden;
  .dealer-details {
    background-color: #e5edee;
    padding: 15px 15px 5px 15px;
    h6 {
      margin-bottom: 5px;
    }
    p {
      margin: 5px 0px;
    }
    table {
      tr {
        td {
          &:first-child {
            width: 100px;
          }
        }
      }
    }
  }

  select,
  #ownerType {
    padding: 5px 10px;
  }

  .page-content {
    ul > li {
      font-size: 16px;
      &::marker {
        color: #007bff;
        font-size: 22px;
      }
    }
    .gray-header {
      color: gray;
      font-weight: 700;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      color: #ababab;
    }

    .content-card-text {
      float: right;
      background: #1996ae;
      color: #fff;
      cursor: pointer;
      p {
        color: #fff;
      }
    }
  }

  .table {
    border: 0 solid transparent;
    th {
      border: 0 solid transparent;
    }
    td {
      border: 0 solid transparent;
      border-bottom: 1px solid #ccc;
    }
  }

  .form-group {
    align-items: baseline;
    label {
      color: black;
      margin-bottom: 0;
    }
    input[type="text"] {
      border: 1px solid #ccc;
    }
    .download-btn {
      margin-left: 10px;
      max-height: 36px;
      width: 35%;
      margin-top: 3px;
      &.vo {
        width: 70%;
      }
    }
    .regNo {
      width: 100%;
    }
  }

  .choose-btn,
  .remove-btn {
    max-height: 36px;
    width: 100px;
  }

  .remove-btn {
    font-size: 12px;
    height: 36px;
    padding: 10px 20px;
  }

  .info-card {
    background-color: #e8f0f1;
    padding: 10px;
    border-radius: 8px;
    color: #5e5e5e;
    h5 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      margin: 5px 0;
    }
  }

  .icon {
    cursor: pointer;
    font-size: 14px;
    margin-left: -30px;
    margin-top: 5px;
    color: #105db5;
    z-index: 999;
  }

  .info-icon {
    color: #53a0f8;
  }

  .period-dropdown {
    width: 100%;
  }

  .addon-dropdown {
    width: 50px;
    padding: 4px;
    margin-right: 5px;
  }

  .button-wrapper {
    display: flex;
    justify-content: end;
    button {
      max-height: 36px;
      margin-left: 10px;
    }
  }

  textarea.form-control {
    min-height: 100px;
  }

  textarea {
    height: 100px;
  }
  .form-check {
    padding-left: 0;
  }

  .form-check-input {
    width: 18px !important;
    margin-left: 3px;
  }

  .form-check-label {
    width: 100%;
    padding-top: 5px;
  }

  .addon-products {
    position: relative;
    padding: 20px 0;
    border-top: 2px solid #03687c;
    border-bottom: 2px solid #03687c;
  }

  .summery-section {
    background-color: #e5edee;
    padding: 10px 10px 5px 10px;
    border-radius: 10px;
    .total-amount {
      position: relative;
      margin-left: 10px;
      &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
        height: 1px; // Adjust the height as needed
        background-color: #000; // Adjust the color as needed
      }
    }
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin-left: auto;
  }

  .label-left,
  .label-right {
    margin: 0 10px;
    font-size: 14px;
    color: #555;
  }

  .error-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.25rem;
    width: 100%;
  }

  .error-container .text-danger {
    margin-top: 5px;
    color: red;
    font-size: 12px;
  }

  .form-group label {
    display: block;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 20px;

      &::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .slider {
      background-color: #007bff;
    }

    input:checked + .slider::before {
      transform: translateX(20px);
    }
  }
}

.registration-complete {
  background-color: #dfffd0;
  padding: 20px;
  margin: 40px auto;
  border-radius: 5px;
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  h6 {
    font-size: 16px;
    margin-top: 15px;
    color: #000;
    font-weight: 500;
  }
}

/* src/components/WarrantyOverview.css */
.warranty-overview {
  margin: 15px auto;
}

h2 {
  text-align: left;
}

.search-bar {
  display: flex;
  justify-content: left;
  margin-bottom: 30px;
}

.search-bar input {
  margin-right: 5px;
  padding: 5px;
  border-radius: 10px;
  width: 200px;
  border: 1px solid gray;
  &:focus {
    border: 1px solid gray;
  }
}

.search-bar button {
  margin-right: 5px;
  padding: 5px 10px;
}

.search-btn {
  font-size: 14px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 10px;
  text-align: left;
  font-size: 12px;
}

th {
  background-color: #f4f4f4;
  color: #3498db;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:nth-child(even) {
  background-color: #fff;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #03687c;
  color: white;
  border: none;
  padding: 7px 15px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button[disabled] {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination .active {
  background-color: #0056b3;
  font-weight: bold;
}

.price-list {
  margin: 15px auto;
}

.pdf-viewer {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.toolbar {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdf-content {
  display: flex;
  flex: 1;
}

.thumbnail-sidebar {
  width: 120px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.thumbnail {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.thumbnail img {
  width: 100%;
  height: auto;
}

.page {
  margin: auto;
  display: flex;
  justify-content: center;
}

.products-sell {
  margin-top: 15px;
}

.product-overview {
  border: 1px solid #ccc;
  header {
    background-color: #3498db;
    text-align: center;
    padding: 5px;
    height: 60px;
    h2 {
      color: #000;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }

  li {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
}
