// Steps
$step-count-per-row: 4;
$step-rounded: true;
$step-border: false;
$step-border-thick: 2px;
$step-border-color: #FFF;
$step-spacing: 10px;
$step-wh: 38px;
$step-font-size: 11px;
$step-color: #9E9E9E;
$step-past-bg: #03687C;
$step-present-bg: #03687C;
$step-future-bg: #F4F4F4;

// Bars
$bar-height: 1px;
$bar-past-bg: $step-past-bg;
$bar-present-bg: #bdc3c7;
$bar-future-bg: #ecf0f1;

// Text and Links
$text-color: #A0A0A0;
$text-font-size: 12px;
$text-hover-color: #03687C;
$text-padding: 0;

.stepper-wrapper {
  width: 63%;
}

.steps {
  // Automagical counting
	counter-reset: counter;

  // Reset list styles
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -41px;


  li {
    margin: $step-spacing 0;
    text-align: center;
    &:before {
      // Count number
      content: counter(counter);
      counter-increment: counter;

      // Location
      float: left;
      margin-right: -$step-wh;

      // Sizing
      box-sizing: border-box;
      display: inline-block;
      width: $step-wh;
      height: $step-wh;
      font-weight: 600;

      @if $step-rounded {
        border-radius: 100%;
      }
      @if $step-border {
        border: $step-border-thick solid $step-border-color;
      }

      // Text
      color: $step-color;
      text-align: center;
      font-size: $step-font-size;
      line-height: $step-font-size;
      padding-top: (($step-wh - $step-font-size) / 2);
      padding-bottom: (($step-wh - $step-font-size) / 2);
      background-color: $step-past-bg;
    }
    &.active {
      &:before {
        background-color: $step-present-bg;
        color: #ffffff;
      }
      a{
        color: $step-present-bg;
      }
    }
    &.active ~ li {
      &:before {
        background-color: $step-future-bg;
        
      }
    }
    &.inactive {
      &:before {
        cursor:pointer;
      }
      a{
        cursor:pointer;
        &:hover{
          color: $text-color;
        }
      }
    }
    &.inactive ~ li {
      &:before {
        cursor:pointer;
      }
      a{
        cursor:pointer;
      }
    }
    a, span {
      // Remove link styling
      text-decoration: none;

      // Sizing
      display: inline-block;
      padding-left: ($step-wh + 10px);

      // Text
      color: $text-color;
      font-size: $text-font-size;
      line-height: 14px;
      padding-top: (($step-wh - $text-font-size) / 2);
      padding-bottom: (($step-wh - $text-font-size) / 2);
    }
    a {
      &:hover, &:active, &:focus {
        color: $text-hover-color;

      }
      font-weight: 600;
      font-size: 14px;
    }
  }

  // Take advantage of flex awesomeness
  display: flex;
  flex-wrap: wrap;
  li {
    // Sizing
    width: calc(100% / #{$step-count-per-row} + 20px);

    // Location
    position: relative;
    &:before {
      // Location
      float: none;
      margin-right: 0;
    }

    &:after {
      // Display
      content: '';
      display: inline-block;

      // Location
      position: absolute;
      top: ($step-wh / 2);
      left: 75%;
      transform: translateY(-50%);
     // z-index: -1;

      // Size
      height: $bar-height;
      //width: calc(100% - #{$step-wh - 10px});
      width: 50%;

      // Make pretty
      border-radius: 5px;
      background-color: $bar-past-bg;
    }
    &:last-of-type {
      &:after {
        // Hide last progress bar
        display: none;
      }
    }
    &.active {
      &:after {
        background-color: $step-present-bg;
      }
    }
    &.active ~ li {
      &:after {
        background-color: $bar-future-bg;
      }
    }
    a, span {
      // Sizing
      display: block;
      padding-left: 0px;
      padding-right: $text-padding;
    }
  }
}

   @media(max-width: 1024px) {
    .information-module {
      .information-module {
        .stepper-wrapper {
          ul.steps {
            margin-left: -11px;
            li {
              width: calc(100% / 4 + 20px);
            }
          }
        }
      }
    }
  }
  
  @media(max-width: 768px) {
    .steps {
      margin-left: 0;
    }
  }

   @media(max-width: 720px) {
    .steps {
      li{
         text-align: left;
         margin-bottom: 5px;
        a{
          
          text-align: left;
        }
      }
    }
  }

/* Small devices */
@media only screen and (max-width: 768px) {
  .product-module,
  .information-module,
  .checkout-module {
    .stepper-wrapper {
      width: 100%;
      ul {
        display: flex;
        justify-content: space-between;
        li {
          a {
            visibility: hidden;
            padding: 0;
          }
        }
      }
    }
  }
}

/* Medium devices */
@media only screen and (max-width: 1024px) {
  .product-module,
  .information-module,
  .checkout-module {
    .stepper-wrapper {
      ul {
        li {
          &::after {
            left: 76%;
          }
        }
      }
    }
  }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .product-module,
  .information-module,
  .checkout-module {
    .stepper-wrapper {
      width: 100%;
      ul {
        padding: 0 35px;
        display: flex;
        justify-content: space-between;
        li {
          a {
            display: none;
            padding: 0;
          }
          &::before {
            width: 25px;
            height: 25px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
          }
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 13px;
            left: 50%;
            transform: translateY(-50%);
            height: 1px;
            width: 70%;
            border-radius: 5px;
          }
          &:last-child {
            width: 50px;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

/* Extra Small devices */
@media only screen and (max-width: 600px) {
  .product-module,
  .information-module,
  .checkout-module {
    .stepper-wrapper {
      ul {
        li {
          &::after {
            left: 43%;
          }
        }
      }
    }
  }
}