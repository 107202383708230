.key-pt-notification{
    .key-pt-sub{
          font-size: 18px;
          color: $color-dark-red2;
          font-weight: bold;
    }
    .key-pt-sub-text{
          font-size: 18px;
          color: $color-body-font;
          font-weight: 400;
    }
    .cart-card{
      font-size: 14px;
      font-weight: normal;
      margin-top: 20px;
      width: 60%;
      .vehicle-no{
        font-size: 14px;
        font-weight: bold;
      }
      a{
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
        color: $color-primary;
        cursor: pointer;
        &:hover{
           text-decoration: none;
        }
      }
      .key-row{
          margin: 10px 0;
      }
    }
}
.kp-letter{
    width: 40px;
}

@media screen and (max-width: 1024px){
    .key-pt-notification{
        .cart-card{
            width: 100%;
        }
    }
    
}

@media screen and (max-width: 575px){
        .dis-mobile{
            display: block;
            width: 100%;
            padding: 5px 0;
            & + .tag-success{
                margin-left: 0;
            }
        }
        .key-pt-notification{
            .cart-card{
                .key-row{
                    margin: 10px 0 20px 0;
                }
            }
    }

}

@media screen and (min-width: 575px){
        .dis-mobile{
        display: none;
        }
}

@media screen and (max-width: 375px){
    .register-link{
        display: block;
        padding-top: 10px;
    }
}