.checkout-form-wrapper{
    display: flex;
    justify-content: flex-end;
    text-align: right;
    form{
        width: 100%;
    }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .checkout-module {
    .alert {
      margin-top: 10px;
    }
  }
  .checkout-form-wrapper {
    justify-content: left;
    text-align: left;
    form {
      >div
      >div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .button-panel {
      display: block;
      flex-wrap: wrap;
      .links {
        display: block;
      }
    }
    .dibspayment {
      &:last-child {
        >div {
          width: 48%;
        }
      }
    }
    .checkout-buttons {
      display: flex;
      justify-content: space-between;
    }
  }
  .m-mb-10{
    margin-bottom: 10px;
  }
}