.tb-review-order{
    
}
.payment-info{
    text-align: left;
    p{
        font-size: 14px;
    }
}
.payment-info-form{
    width: 50%;
}


.alert {
    padding: 10px;
    background-color: $color-dark-red2;
    color: white;
}
  
.alert  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}
  
.alert  .closebtn:hover {
    color: black;
}

.alert a{
    color:$color-white;
    text-decoration: underline;
    cursor: pointer;
}

.OverlappingAlert {
    padding: 5px;
    background-color: $color-dark-red2;
    color: white;
}

@media screen and (max-width: 767px){
        .payment-info-form{
            width: 100%;

        }

        .m-mb-10{
            margin-bottom: 10px;
          }
}

@media screen and (max-width: 1024px){
    .dibspayment{
            img{
                width: 100%;
                height: 100%;
            }
    
        }
}