// Thank you page

.thankyou-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  .confirm-icon-background{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #E5EDEE;
      border-radius: 10px;
      width: 120px;
      height: 120px;

    .confrim-icon{
      background: url('../../../images/cancel.svg');
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
    }
  }

  .content-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
  }

  h1{
    margin: 0px;
    text-align: center;
    color: #03687C;
    margin-bottom: 5px;
    font-size: 38px;
  }

  p{
    margin: 0px;
    text-align: center;
    color: #999;
    margin-bottom: 0;
  }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .thankyou-wrapper{
    margin-top: 10px;
    .confirm-icon-background {
      display: none;
      width: 100px;
      height: 100px;
      .confrim-icon-accepted {
        width: 50px;
        height: 50px;
        background-size: contain;
      }
    }
    .content-wrap {
      h1 {
        margin-bottom: 10px !important;
        font-size: 28px;
      }
    }
    .cart-card {
      display: block;
    }
    p {
      margin-bottom: 10px;
    }
  }
}