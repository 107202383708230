.loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    background-color:$color-white;
    padding: 10px;
    border-radius: 10px;
}
.loading-wrapper .loading-icon {
    width: 71px;
    height: 71px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    background: url('../images/loading.gif'); 
   // opacity: 0.6;
}
.loading-wrapper .content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center; 
}
.loading-wrapper h1 {
    margin: 0px;
    text-align: center;
    color: $color-primary;
    margin-bottom: 5px;
    font-size: 38px; 
}
.loading-wrapper p {
    margin: 0px;
    text-align: center;
    color: $color-primary;
    margin-bottom: 20px; 
}