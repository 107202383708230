.edt-profile {
  margin-top: 25px;
}
.loader {
  width: 45px;
  height: 17px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  33% {background-size: 20% 10% ,20% 100%,20% 100%}
  50% {background-size: 20% 100%,20% 10% ,20% 100%}
  66% {background-size: 20% 100%,20% 100%,20% 10% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}
.hide-element{
  display: none;
}


.m-l-0 {
  margin-left: 0px !important;
}
.m-l-cus-sup {
  margin-left: 5px;
}
.menu-bar .hs-menu-wrapper > ul > li > ul > li:first-child:before {
  left: 150px !important;
}
.login-module,
.offer-module,
.profile-module,
.information-module,
.review-module,
.product-module,
.checkout-module,
.payment-accepted-module,
.payment-cancel-module,
.warranty-module,
.new-vehicle-module,
.keyprotect-registration-module,
.new-customer-flow,
.international-claim-module,
.warranty-registration-module {
  font-family: Helvetica;
  font-size: 18px;
  color: #454545;
  line-height: initial;

  @import "colors";
  @import "typography";
  @import "stepper";
  @import "common";
  @import "buttons";
  @import "popup";
  @import "products";
  @import "insure-another";
  @import "login";
  @import "loading";
  @import "checkout";
  @import "forms";
  @import "new-form";
  @import "datepicker";
  @import "data-table";
  @import "owner-details";
  @import "review-order";
  @import "key-protect";
  @import "new-customer";
  @import "tooltip";
  @import "profile";
  @import "claim";
  @import "dealer-details";
}
