.lang_switcher_class{position:relative;display:inline-block}.globe_class{background-image:url(//static.hsappstatic.net/cos-LanguageSwitcher/static-1.11/img/globe.png);background-position:50%;background-repeat:no-repeat;background-size:cover;border-radius:10px;width:20px;height:20px;cursor:pointer}.lang_list_class{display:none;position:absolute;top:17px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);text-align:center;z-index:100;list-style-type:none;padding:15px 0 0;margin:0}.globe_class:hover .lang_list_class{display:block}.lang_list_class:before{top:-8px;border:12px solid transparent;border-bottom-color:#000}.lang_list_class:after,.lang_list_class:before{content:"";display:block;position:absolute;left:50%;width:0;height:0;margin-left:-12px}.lang_list_class:after{top:-7px;border:12px solid transparent;border-bottom-color:#fff;margin-bottom:-1px}.lang_list_class li{position:relative;line-height:1;font-size:13px;padding:0 15px 10px;background-color:#fff;border-left:1px solid #000;border-right:1px solid #000}.lang_list_class li:first-child{padding-top:15px;border-top:1px solid #000}.lang_list_class li:last-child{padding-bottom:15px;border-bottom:1px solid #000}.lang_list_class li a{text-decoration:none;color:#000;cursor:pointer}.lang_list_class li a:hover{color:#02f}
a.cta_button {
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
    vertical-align: middle;
  }
  .hs-breadcrumb-menu {
    list-style-type: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .hs-breadcrumb-menu-item {
    float: left;
    padding: 10px 0px 10px 10px;
  }
  .hs-breadcrumb-menu-divider:before {
    content: "›";
    padding-left: 10px;
  }
  .hs-featured-image-link {
    border: 0;
  }
  .hs-featured-image {
    float: right;
    margin: 0 0 20px 20px;
    max-width: 50%;
  }
  @media (max-width: 568px) {
    .hs-featured-image {
      float: none;
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }
  .hs-screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }


/*# sourceMappingURL=LanguageSwitcher.css.map*/