.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-group label {
  flex: none;
  display: block;
  width: 140px;
}
.form-group label.right-inline {
  text-align: right;
  padding-right: 8px;
  padding-left: 10px;
  width: auto;
}

.form-group {
  .input-group,
    //.input-group-pid,
    .input-group-city,
    .input-group-email {
    > div {
      display: flex;
      flex-direction: column;

      label {
        order: -1;
        padding-left: 10px;
        transition: all 0.3s ease-in;
        transform: translateY(22px);
        opacity: 0.5;
        pointer-events: none;
        height: 10px;
      }
      input:focus + label {
        transform: translateY(0);
        opacity: 1;
      }
      input:not(:placeholder-shown) + label {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .input-group-email {
    > div {
      label {
        pointer-events: all;
      }
      .email-label {
        pointer-events: none;
      }
    }
  }
}

.form-group .input-group {
  flex: 0.5;
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  padding: 4px;
  margin-top: -4px;
}

.form-group .input-group-pid {
  flex: 0.5;
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  padding: 4px 4px 4px 2px;
  margin-top: -4px;
  .btn-get-info {
    padding: 0 20px;
  }
}

.form-group .input-group-city {
  flex: 0.5;
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  padding: 4px;
  margin-top: -4px;
}

.form-group .input-group-email {
  flex: 0.5;
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  padding: 4px;
  margin-top: -4px;
}

.help-text-email {
  max-width: 600px;
  font-size: 12px;
  color: $color-primary;
  margin: 0;
  margin-top: 5px;
}

.personal-number a {
  margin-left: 8px;
}

@media (max-width: 768px) {
  .form-group .input-group {
    margin-top: 1px;
  }
  .form-group label.right-inline {
    text-align: left;
    padding-right: 0;
    padding-left: 0;
  }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .information-module {
    .form-group {
      flex-direction: column;
      .input-group-pid {
        padding: 0;
        margin: 0;
      }
      &.row-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .input-group {
          padding: 0;
          margin: 0;
          width: calc(50% - 10px);
          &:first-child {
            margin-right: 4px;
          }
          &:last-child {
            margin-left: 4px;
          }
        }
      }
      .input-group,
      .input-group-email,
      .input-group-city {
        margin: 0;
        padding: 4px 0;
      }
      .column {
        margin-bottom: 0;
      }
    }
  }
}

/* Large devices */
@media only screen and (min-width: 1024px) {
  .information-module {
    .form-group {
      .input-group-pid,
      .input-group,
      .input-group-email,
      .input-group-city {
        margin-bottom: 5px;
      }
    }
  }
}
